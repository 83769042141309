<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <PageHeaderApplication>
        Line of Credit Application - Personal
        <template v-slot:subtitle>
          <span>Find out if you qualify in these simple steps.</span>
        </template>
      </PageHeaderApplication>

      <v-form
        :validation-schema="schema"
        keep-values
        @submit="completeSection"
        @invalid-submit="onIncompleteSection"
        v-slot="{ errors }"
      >
        <section>
          <AppNav :navselected="1" />
          <fieldset class="form">
            <legend class="visually-hidden">
              Line of Credit Application - Personal
            </legend>
            <h3 class="contentheader" id="tittlepersonal" tabindex="-1">
              Let's get started. Tell us about yourself.
            </h3>
            <div class="inputs">
              <FormField :errormsg="errors['firstname']">
                <template v-slot:label>
                  <label for="firstname" class="applabel"
                    >FIRST NAME</label
                  ></template
                >
                <v-field
                  v-model="firstName"
                  name="firstname"
                  v-slot="{ field }"
                >
                  <input
                    v-bind="field"
                    type="text"
                    id="firstname"
                    @keydown="limitAlphabates"
                    aria-describedby="firstname-errormsg"
                    aria-required="true"
                    aria-invalid="true"
                  />
                </v-field>
                <template v-slot:error>
                  <v-error id="firstname-errormsg" name="firstname" />
                </template>
              </FormField>

              <FormField :errormsg="errors['lastname']">
                <template v-slot:label>
                  <label for="lastname" class="applabel">Last Name</label>
                </template>
                <v-field
                  v-model.trim="lastName"
                  name="lastname"
                  v-slot="{ field }"
                >
                  <input
                    v-bind="field"
                    type="text"
                    id="lastname"
                    @keydown="limitAlphabates"
                    aria-describedby="lastname-errormsg"
                    aria-required="true"
                    aria-invalid="true"
                  />
                </v-field>
                <template v-slot:error>
                  <v-error id="lastname-errormsg" name="lastname" />
                </template>
              </FormField>
            </div>
            <div class="inputs">
              <FormField :errormsg="errors['dob']">
                <template v-slot:label>
                  <label for="birthdate" class="applabel"
                    >BIRTHDATE<span class="lowercase"> mm/dd/yyyy</span></label
                  ></template
                >
                <!-- <input
                  :value="birthdate | date"
                  @change="updateBirthdate($event.target.value)"
                  maxLength="10"
                  id="birthdate"
                  @keypress="allowonlynumbersandbackslash"
                  @blur="validateBirthDate()"
                  aria-describedby="dateofbirth-errormsg"
                />
                <DatePicker
                  ref="birthdate"
                  name="dob"
                  v-model="birthdate"
                  format="MM/dd/yyyy"
                  @selected="updateBirthdate"
                  calendar-class="datepicker-calendar"
                  input-class="datepicker-input"
                  wrapper-class="datepicker-input-wrapper"
                  :disabled-dates="birthdateDisabledDates"
                  :open-date="birthdateStartDate"
                  v-validate="{
                    required: true,
                    min: 8,
                  }"
                />
                <div class="datepicker-icon" @click="openCalendar">
                  <i class="fas fa-calendar-alt fa-2x" />
                </div> -->

                <b-input-group class="inputgroupdatewrapper">
                  <v-field v-model="birthdate" name="dob" v-slot="{ field }">
                    <b-form-input
                      v-bind="field"
                      type="text"
                      id="birthdate"
                      autocomplete="off"
                      maxLength="10"
                      aria-describedby="birthdate-errormsg dateofbirth-errormsg"
                      aria-required="true"
                      @keypress="allowonlynumbersandbackslash"
                      @blur="validateBirthDate()"
                      aria-invalid="false"
                    ></b-form-input>
                  </v-field>
                  <v-field
                    v-model="birthdatenewformat"
                    name="dobformat"
                    v-slot="{ field }"
                  >
                    <b-input-group-append>
                      <b-form-datepicker
                        v-bind="field"
                        button-only
                        right
                        locale="en-US"
                        aria-controls="birthdate"
                        :max="maxDateinput"
                        :min="minDateinput"
                        @context="onContext"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </v-field>
                </b-input-group>

                <template v-slot:error>
                  <v-error
                    id="birthdate-errormsg"
                    name="dob"
                    v-if="birthdateerror == ''"
                  />
                  <span
                    v-else
                    id="dateofbirth-errormsg"
                    v-text="birthdateerror"
                  ></span>
                </template>
              </FormField>
              <FormField :errormsg="errors['socsecurity']">
                <template v-slot:label>
                  <label for="socsecurity" class="applabel"
                    >Social Security #&nbsp;&nbsp; ###-##-####
                  </label></template
                >
                <v-field
                  name="socsecurity"
                  id="socsecurity"
                  v-model.trim="social"
                  v-on:keypress="isNumber($event)"
                  @keydown="limitNums"
                  maxLength="11"
                  @blur="ssnValidate()"
                  aria-describedby="ssn-errormsg"
                />
                <template v-slot:error>
                  <v-error id="ssn-errormsg" name="socsecurity" />
                </template>
              </FormField>
            </div>

            <div class="inputs">
              <FormField :errormsg="errors['email']">
                <template v-slot:label>
                  <label for="email" class="applabel">Email</label>
                </template>
                <v-field
                  name="email"
                  id="email"
                  v-model.trim="email"
                  type="email"
                  aria-describedby="email-errormsg"
                />
                <template v-slot:error>
                  <v-error id="email-errormsg" name="email" />
                </template>
              </FormField>

              <FormField :errormsg="errors['confirmemail']">
                <template v-slot:label>
                  <label for="confirmemail" class="applabel"
                    >Confirm Email</label
                  >
                </template>
                <v-field
                  v-model.trim="confirmEmail"
                  name="confirmemail"
                  v-slot="{ field }"
                >
                  <input
                    v-bind="field"
                    id="confirmemail"
                    @paste.prevent                    
                    type="email"
                    aria-describedby="confirmemail-errormsg"
                /></v-field>
                <template v-slot:error>
                  <v-error id="confirmemail-errormsg" name="confirmemail" />
                </template>
              </FormField>
            </div>
          </fieldset>
          <div class="upperbottom" role="alert" v-show="showcommonerrormsg">
            {{ help.commonapperror }}
          </div>
          <div class="bottom">
            <p class="privacy">
              <!-- {{ legal.agreeToPrivacyPolicy }} -->
              By continuing, you agree to the
              <a
                class="doc-link"
                id="lnkTermsCondition"
                @click="openDocument('tc')"
                @keypress.enter="openDocument('tc')"
                @keydown.space="openDocumentKeySpace('tc', $event)"
                role="button"
                aria-label="View Terms and Conditions"
                tabindex="0"
                >Terms and Conditions</a
              >
              and
              <a
                class="doc-link"
                id="lnkPrivacyPolicy"
                @click="openDocument('priv')"
                @keypress.enter="openDocument('priv')"
                @keydown.space="openDocumentKeySpace('priv', $event)"
                role="button"
                aria-label="View Privacy Policy"
                tabindex="0"
                >Privacy Policy</a
              >
              of this site and are providing express written consent for
              XactFlex to contact you by telephone at the numbers you provide,
              even if your phone number is on any Do-Not-Call registry, in order
              to finalize your line of credit application.
            </p>
            <button class="button-orange">CONTINUE</button>
          </div>
          <div>
            <input id="ioBB" name="ioBB" type="hidden" />
          </div>
        </section>
      </v-form>
    </div>
  </div>
</template>

<script>
import legal from "@/config/text/legal";
import help from "@/config/text/help";
import { FormField } from "@/components";
import AppNav from "@/components/application/AppNav.vue";
// import DatePicker from "vuejs-datepicker";
//import { Validator } from "vee-validate";
import moment from "moment";
import { limitNums, limitAlphabates } from "@/utils/plugins/inputs";
import PageHeaderApplication from "@/components/global/PageHeaderApplication";
import { birthdateStartDate, birthdateDisabledDates } from "@/config/dates";

import "../../styles/datepicker_bootstrap.scss";
import "../../styles/datepicker_bootstrapvue.scss";
import { date } from "@/utils/filters/dates";
import * as yup from "yup";

export default {
  name: "AppPersonal",
  title: "Line of Credit Application",
  components: {
    PageHeaderApplication,
    FormField,
    AppNav,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    const minDate = new Date(new Date("1900", "0", "1"));
    minDate.setFullYear(minDate.getFullYear());

    const SSNRegExp =
      "^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$";
    let EMAIL_REGX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
    //var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
    let firstnameErrormsg = "Please enter your first name";
    let lastnameErrormsg = "Please enter your last name";
    let socsecurityErrormsg =
      "Please enter your 9-digit social security number";
    let emailErrormsg =
      "Please enter your email address in this format: name@example.com";
    let confirmemailErrormsg =
      "Please confirm your email address in this format: name@example.com";
    let confirmedErrormsg = "Email addresses must match";
    let dateofbirthErrormsg =
      "Please enter your birthdate. You must be at least 18 to apply.";
    let minDateofbirthErrormsg =
      "Year must be greater than or equal to 1900.";      
    const schema = yup.object().shape({
      firstname: yup.string().required(firstnameErrormsg),
      lastname: yup.string().required(lastnameErrormsg),
      socsecurity: yup
        .string()
        .matches(SSNRegExp, socsecurityErrormsg)
        .required(socsecurityErrormsg)
        .min(11, socsecurityErrormsg)
        .max(11, socsecurityErrormsg),
      email: yup
        .string()
        .required(emailErrormsg)
        .matches(EMAIL_REGX, emailErrormsg),
      confirmemail: yup
        .string()
        .required(confirmemailErrormsg)
        .matches(EMAIL_REGX, confirmemailErrormsg)
        .oneOf([yup.ref("email"), null], confirmedErrormsg),
      dob: yup
        .string()
        .required(dateofbirthErrormsg)
        .min(10, dateofbirthErrormsg)
        .test("maxdate", dateofbirthErrormsg, (val) => {
            if(val != undefined && this.birthdate.length >= 10){
                  const inpdate = new Date(new Date(this.birthdate.substring(6, 10), this.birthdate.substring(0, 2)-1, this.birthdate.substring(3, 5)));
                  if(inpdate > maxDate)
                  {
                      return false
                  }
            }
            return true;
          })
        .test("mindate", minDateofbirthErrormsg, (val) => {
            if(val != undefined && this.birthdate.length >= 10){
                  const inpdate = new Date(new Date(this.birthdate.substring(6, 10), this.birthdate.substring(0, 2)-1, this.birthdate.substring(3, 5)));
                  if(inpdate < minDate)
                  {
                      return false
                  }
              }
            return true;
          }),          
    });

    return {
      schema,
      legal: legal,
      help: help,
      showcommonerrormsg: false,
      birthdateerror: "",
      birthdateStartDate: birthdateStartDate,
      birthdateDisabledDates: birthdateDisabledDates,
      maxDateinput: maxDate,
      minDateinput: minDate,
      date: date,
    };
  },
  created() {
    if (!this.$store.state.application.personalfrombacktabs) {
      this.$store.commit("resetApplication");
    }
    this.$store.commit("updatepersonalfrombacktabs", false);
  },
  mounted() {
    this.focusfirstelement();
    document.getElementById("divpersonal").setAttribute("aria-current", "step");
  },

  computed: {
    reloadcurrent: {
      get() {
        return this.$store.state.application.reloadpage;
      },
      set(value) {
        this.$store.commit("updatereload", value);
      },
    },
    firstName: {
      get() {
        return this.$store.state.application.firstName;
      },
      set(value) {
        this.$store.commit("updateFirstName", value);
      },
    },
    lastName: {
      get() {
        return this.$store.state.application.lastName;
      },
      set(value) {
        this.$store.commit("updateLastName", value);
      },
    },
    birthdate: {
      get() {
        if (
          this.$store.state.application.birthdate != null &&
          this.$store.state.application.birthdate.toString().includes("-") &&
          this.$store.state.application.birthdate.length == 10
        ) {
          return moment(
            String(this.$store.state.application.birthdate),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        //console.log("birthdate- " + this.$store.state.application.birthdate);
        return this.$store.state.application.birthdate;
      },
      set(value) {
        if (value.toString().length > 10) {
          value = moment(value, "MM/DD/YYYY").format("MM/DD/YYYY");
        }
        this.$store.commit("updateBirthdate", value);
      },
    },
    birthdatenewformat: {
      get() {
        if (String(this.birthdate).length == 10) {
          var val = moment(String(this.birthdate), "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          //console.log("birthdateformat- " + val);
          return val;
        } else {
          return this.birthdate;
        }
      },
      set(value) {
        // console.log(value);
        this.birthdate = value;
      },
    },
    social: {
      get() {
        return this.$store.state.application.social;
      },
      set(value) {
        this.$store.commit("updateSocial", value);
      },
    },
    email: {
      get() {
        return this.$store.state.application.email;
      },
      set(value) {
        this.$store.commit("updateEmail", value.toLowerCase());
      },
    },
    confirmEmail: {
      get() {
        return this.$store.state.application.confirmEmail;
      },
      set(value) {
        this.$store.commit("updateConfirmEmail", value.toLowerCase());
      },
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.selected = ctx.selectedYMD;
      if (
        ctx.selectedYMD != null &&
        ctx.selectedYMD != "" &&
        ctx.selectedYMD != "2001-01-01" &&
        ctx.selectedYMD.length == 10
      ) {
        this.validateBirthDate();
      }
    },
    focusfirstelement() {
      document.getElementById("tittlepersonal").focus();
    },
    completeSection() {
      this.validateBirthDate();
      if (this.schema.isValid && this.birthdateerror == "") {
        this.showcommonerrormsg = false;
        this.ssnValidate();
        this.$store.commit("updatecompletePersonal", true);
        this.$router.push({ name: "app-address" });
      } else {
        this.showcommonerrormsg = true;
      }
    },
    onIncompleteSection() {
      this.showcommonerrormsg = true;
    },
    openCalendar() {
      this.$refs.birthdate.showCalendar();
    },
    updateBirthdate(eventval) {
      var inval = eventval;
      if (inval == "" || inval == null) {
        this.birthdate = "";
        this.birthdateerror = "";
        return;
      }
      if (!String(inval).includes("/")) {
        inval = moment(inval, "MM/DD/YYYY").format("MM/DD/YYYY");
      }
      var dateArray = inval.split("/");
      if (dateArray != null && dateArray.length == 3) {
        if (dateArray[0].length == 1) {
          dateArray[0] = "0" + dateArray[0];
        }
        if (dateArray[1].length == 1) {
          dateArray[1] = "0" + dateArray[1];
        }
        if (
          dateArray[0].length == 2 &&
          dateArray[1].length == 2 &&
          dateArray[2].length == 4
        ) {
          this.birthdate = dateArray.toString().replaceAll(",", "/");
        } else {
          this.birthdate = "";
        }
      } else {
        this.birthdate = "";
        this.birthdateerror = "";
        return;
      }
    },
    isLetter(e) {
      //console.log("Fired");
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isDatestring(e) {
      var char = String.fromCharCode(e.keyCode); // Get the character
      if (char.match(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/))
        return true;
      else e.preventDefault();
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    allowonlynumbersandbackslash(event) {
      // console.log("called event");
      // console.log("called event value:" + event.target.value);
      if (event.target.value == "" || event.target.value == null) {
        return;
      }
      var keyCode = event.keyCode;
      var isShift = false;
      if (keyCode == 16) {
        isShift = true;
      }
      //Allow only Numeric Keys.
      if (
        ((keyCode >= 47 && keyCode <= 57) ||
          keyCode == 8 ||
          keyCode <= 37 ||
          keyCode <= 39) &&
        isShift == false
      ) {
        if (keyCode == 47) {
          if (
            event.target.value != null &&
            event.target.value.length >= 1 &&
            event.target.value.length <= 5 &&
            keyCode != 8
          ) {
            return true;
          } else {
            event.preventDefault();
          }
        } else {
          var inval = event.target.value;
          if (
            inval != null &&
            (inval.length == 2 || inval.length == 5) &&
            keyCode != 8 &&
            keyCode != 47
          ) {
            let length = inval.length - 1;
            let count = inval.split("/").length;
            if (inval[length] != "/" && count <= 2)
              event.target.value = inval + "/";
          }
        }
      } else {
        event.preventDefault();
      }
    },
    validateBirthDate() {
      if (
        this.birthdate == null ||
        this.birthdate == undefined ||
        this.birthdate == "" ||
        this.birthdate.length < 10
      ) {
        this.schema.validateAt("dob", this.birthdate).catch(function (err) {
          err.name; // => 'ValidationError'
          err.errors; // => error message
        });
        //document.getElementById("birthdate").setAttribute('aria-invalid', true);
        return true;
      }
      document.getElementById("birthdate").setAttribute("aria-invalid", false);
      var val = this.birthdate;
      var dateArray = val.split("/");
      if (dateArray == null || dateArray.length != 3) {
        this.birthdate = "";
        this.schema.validateAt("dob", this.birthdate).catch(function (err) {
          err.name; // => 'ValidationError'
          err.errors; // => error message
        });
        //document.getElementById("birthdate").setAttribute('aria-invalid', true);
        return true;
      }
      try {
        var inp_date = moment(this.birthdate, "MM/DD/YYYY").format(
          "MM/DD/YYYY"
        );

        if (inp_date == "Invalid date") {
          this.birthdate = "";
          this.birthdateerror = "";
          this.schema.validateAt("dob", this.birthdate).catch(function (err) {
            err.name; // => 'ValidationError'
            err.errors; // => error message
          });
          return true;
        }
        var cur_date = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");
        var min_eligible_date = moment(cur_date, "MM/DD/YYYY").add(
          -18,
          "years"
        );
        if (
          moment(inp_date, "MM/DD/YYYY").isAfter(min_eligible_date) ||
          !moment(inp_date, "MM/DD/YYYY").isValid()
        ) {
          this.birthdateerror =
            "Please enter your birthdate. You must be at least 18 to apply.";
          return true;
        }
      } catch (error) {
        //console.log(error);
      }
      this.birthdateerror = "";
      return false;
    },
    async ssnValidate() {
      if (this.social != "" && this.social != null) {
        await this.$store.dispatch("createToken");
        this.$store.state.application.tokenID = this.$store.state.login.token;
        const result = await this.$store.dispatch("ssnValidate");
        //console.log(result);
        if (result.customerExists == true) {
          this.$router.push({ name: "login" });
        }
      }
    },
    openDocument(doc) {
      this.$store.dispatch("getDocument", doc);
    },
    openDocumentKeySpace(doc, event) {
      event.preventDefault();
      this.$store.dispatch("getDocument", doc);
    },
    limitNums,
    limitAlphabates,
  },
};
// Validator.localize({
//   en: {
//     custom: {
//       firstname: {
//         required: "Please enter your first name",
//       },
//       lastname: {
//         required: "Please enter your last name",
//       },
//       dob: {
//         required:
//           "Please enter your birthdate. You must be at least 18 to apply.",
//         before:
//           "Please enter your birthdate. You must be at least 18 to apply.",
//         min: "Please enter your birthdate. You must be at least 18 to apply.",
//         length:
//           "Please enter your birthdate. You must be at least 18 to apply.",
//         date_format: "Please enter your birthdate in this format: mm/dd/yyyy.",
//       },
//       socsecurity: {
//         required: "Please enter your 9-digit social security number",
//         socialLength: "Please enter your 9-digit social security number",
//       },
//       email: {
//         email:
//           "Please enter your email address in this format: name@example.com",
//       },
//       confirmemail: {
//         email:
//           "Please confirm your email address in this format: name@example.com",
//         confirmed: "Email addresses must match",
//       },
//     },
//   },
// });
</script>

<style scoped lang="scss">
form {
  width: 100%;

  section {
    display: flex;
    flex-flow: column nowrap;
    max-width: 1023px !important;

    @media (max-width: 767px) {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }

  .form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 30px 20px 30px 20px;
    margin-top: 23px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $gray;
    justify-content: space-evenly;
    align-items: left;
    border-radius: 6px;

    @media (max-width: 767px) {
      max-width: 720px;
      margin: 5% 0px !important;
      padding: 20px !important;
    }
    .inputs {
      display: flex;
      flex-flow: row nowrap;

      @media (max-width: 767px) {
        flex-flow: column nowrap;
      }
    }
  }

  .contentheader {
    font-weight: 600;
    margin-left: 78px;
    margin-bottom: 10px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 15px;
    }
    @media (max-width: 767px) {
      margin-left: 5px;
      margin-right: 15px;
      font-size: 20px;
    }

    @media (max-width: 428px) {
      //margin-left: 5px;
      margin-right: 5px;
      font-size: 18px;
    }

    @media (max-width: 380px) {
      font-size: 15px;
    }
  }

  input {
    border: solid 1px $fieldborder-gray;
    padding: 3px 5px 3px 5px;
  }

  .field {
    margin-left: 75px;
    margin-right: 75px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 10px;
      margin-right: 20px;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .radio-input {
    margin: 20px 120px 0 0;
  }

  .applabel {
    font-weight: 600;
  }

  .bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    // padding: 20px 0 0;
    margin-left: 0;
    align-items: center;
    margin-right: 0;

    @media (max-width: 767px) {
      padding: 0;
      flex-flow: column-reverse nowrap;
    }
  }
  .upperbottom {
    text-align: right;
    color: $error;
    margin: 15px 0 10px;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 0px;
    }
  }
  .privacy {
    width: 70%;
    a {
      font-weight: 600;
      color: $blue;
      border-color: $blue;
    }

    @media (max-width: 767px) {
      flex-flow: column nowrap;
      padding: 0px;
      width: 100%;
    }
  }

  .subnote {
    height: 15px;
    width: 240px;
    margin: 20px auto;
  }

  .error {
    height: 15px;
    max-width: 312px;
    margin: 0 5px;
    font-size: 13px;
    color: $error;
  }

  .repaymentMethod {
    position: relative;
    margin-top: 20px;
  }
  .repaymentMethodSpan {
    width: 350px;
    position: absolute;
  }
  @media (max-width: 1023px) {
    .banking-info {
      flex-flow: column nowrap;
      align-items: center;
    }

    .radio-input {
      margin: 20px 20px 0 0;
    }

    .bottom {
      //padding: 40px 0 0;
      @media (min-width: 768px) and (max-width: 1023px) {
        margin-left: 18px;
        margin-right: 18px;
      }
      .inputs {
        flex-flow: column-reverse nowrap;
        align-items: center;

        button {
          margin-bottom: 10px;
        }
      }
    }
  }
  .button-orange {
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 25%;
    }

    @media (max-width: 767px) {
      width: 50%;
      padding: 6px 40px;
      max-width: inherit;
    }
  }
  .lowercase {
    text-transform: lowercase;
  }
}
</style>