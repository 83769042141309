<template>
  <header>
    <div class="wrapper-full hide-small">
      <div class="logo">
        <router-link :to="{ name: 'home' }" class="no-border">
          <img
            src="../../assets/logos/xactLogo.png"
            alt="Xact Flex.com home page"
            class="xact-logo"
          />
        </router-link>
      </div>
      <div class="top-nav navbar-nav ms-auto">
        <div class="col-md-6 wrapper-call-login">
          <a
            class="call no-border no-hover"
            :href="'tel:' + companyInfo.contactPhone"
            >Call Us: {{ companyInfo.contactPhone }}</a
          >
          <button
            tabindex="0"
            class="border-hover Newlogin-link"
            @click="logout"
            v-if="(loggedIn || isEsignContact) && !isEsignPage && !isAppPage"
          >
            Log Out
          </button>
          <button
            @click="login"
            class="border-hover Newlogin-link"
            v-if="
              !isLoginPage &&
              !loggedIn &&
              !isEsignContact &&
              !isEsignPage &&
              !isAppPage
            "
          >
            Log In
          </button>
        </div>
        <div class="col-md-6">
          <nav
            role="navigation"
            aria-label="header navigation"
            class="links"
            v-if="!isEsignPage && !isAppPage"
          >
            <div v-if="loggedIn && isPortalPage">
              <div id="welcomeback" tabindex="-1" class="hello">
                Welcome back, {{ firstName }}
              </div>
            </div>

            <router-link
              :to="{ name: 'login' }"
              class="border-hover login-link"
              v-if="loggedIn && !isPortalPage"
              >Back to Account</router-link
            >
            <router-link
              :to="{ name: 'app-personal' }"
              v-if="!isEsignContact && !isPortalPage && !loggedIn"
              class="border-hover blue italic"
              >Apply Now</router-link
            >
            <span class="border-md-right"></span>
            <router-link :to="{ name: 'home' }" class="border-hover"
              >Home</router-link
            >
            <span class="border-md-right"></span>
            <a
              href="https://ccbank.everfi-next.net"
              target="_blank"
              class="border-hover"
              >Financial Education</a
            >
            <span class="border-md-right"></span>
            <router-link :to="{ name: 'faq' }" class="border-hover"
              >FAQs</router-link
            >
            <span class="border-md-right"></span>
            <router-link :to="{ name: 'about-us' }" class="border-hover"
              >About Us</router-link
            >
          </nav>
        </div>
      </div>
    </div>

    <div class="wrapper-mob hide-large">
      <div class="logo" :class="{ dropped: showDropdown }">
        <router-link :to="{ name: 'home' }" class="no-border">
          <img
            src="../../assets/logos/xactLogo.png"
            alt="Xact"
            class="xact-logo"
            @click="showDropdown = false"
          />
        </router-link>
        <div class="button-orange-header" v-if="isAppPage">
          <a
            class="call no-border no-hover"
            :href="'tel:' + companyInfo.contactPhone"
            >CALL US</a
          >
        </div>
        <i
          class="fas fa-bars fa-2x"
          @click="toggleNav"
          v-show="!showDropdown && !isEsignPage && !isAppPage"
        />
        <i
          class="fas fa-times fa-2x"
          @click="toggleNav"
          v-show="showDropdown && !isEsignPage && !isAppPage"
        />
        <div class="top-nav navbar-nav ms-auto" v-if="isEsignPage">
          <div class="button-orange-header">
            <a
              class="call no-border no-hover"
              :href="'tel:' + companyInfo.contactPhone"
              >CALL US</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="mb-user" v-if="loggedIn && displayUnlessPortal && !isEsignPage">
      <div class="hide-large-s">Welcome back, {{ firstName }}</div>
    </div>

    <div class="sidenav" v-show="showNav" @click="toggleNav">
      <a class="closebtn">&times;</a>
      <div v-if="!isLoginPage && !isEsignPage">
        <a
          class="item no-border login-link"
          @click="logout"
          v-if="loggedIn || isEsignContact"
          >Log Out</a
        >
        <router-link
          :to="{ name: 'login' }"
          class="item no-border login-link"
          v-else
          >Log In</router-link
        >
        <router-link
          :to="{ name: 'login' }"
          class="border-hover login-link"
          v-if="loggedIn && !isPortalPage"
          >Back to Account</router-link
        >
        <router-link :to="{ name: 'home' }" class="item no-border"
          >Home</router-link
        >
        
        <a
          href="https://ccbank.everfi-next.net"
          target="_blank"
          class="border-hover"
          >Financial Education</a
        >

        <router-link :to="{ name: 'faq' }" class="item no-border"
          >FAQs</router-link
        >
        <router-link :to="{ name: 'about-us' }" class="item no-border"
          >About Us</router-link
        >
        <a
          class="call no-border no-hover"
          :href="'tel:' + companyInfo.contactPhone"
          >Call Us: <u>{{ companyInfo.contactPhone }}</u></a
        >
      </div>
      <div v-show="hidelogin">
        <router-link :to="{ name: 'home' }" class="item no-border"
          >Home</router-link
        >
        <router-link :to="{ name: 'faq' }" class="item no-border"
          >FAQs</router-link
        >
        <router-link :to="{ name: 'about-us' }" class="item no-border"
          >About Us</router-link
        >
        <!--<a class="call no-border no-hover" :href="'tel:' + companyInfo.contactPhone">Call Us: <u>{{ companyInfo.contactPhone }}</u></a> -->
      </div>
    </div>
    <div class="hide-large" v-show="showNav" @click="toggleNav"></div>
  </header>
</template>

<script>
import companyInfo from "@/config/text/company";
import { stateWithCodes } from "@/config/states";

export default {
  name: "Header",

  data() {
    return {
      showDropdown: false,
      showNav: false,
      companyInfo: companyInfo,
      current_env: process.env.VUE_APP_ENVIRONMENTDISPLAY,
      stateWithCodes : stateWithCodes
    };
  },
   created() {
    let queryString = window.location.search;
    //console.log(queryString);
    let urlParams = new URLSearchParams(queryString);
    if( urlParams.has('utm_campaign') ){
        this.$store.commit("updateGoogleCampaignCode", "GOOG-" + urlParams.get('utm_campaign'));
    }

    /*
    let tempRecipes = stateWithCodes.filter((item) => {
      if(item.text=="Texas")
      {
        return (item)
      }
      
    });
console.log(tempRecipes[0].value);
*/

   },
  computed: {
    email: {
      get() {
        return this.$store.state.login.email;
      },
      set(value) {
        this.$store.commit("updateLoginEmail", value);
      },
    },
    loggedIn: {
      get() {
        return this.$store.state.login.loggedIn;
      },
    },
    firstName: {
      get() {
        return this.$store.state.login.firstName;
      },
    },
    shortfirstName: {
      get() {
        return this.$store.state.login.firstName != null &&
          this.$store.state.login.firstName.length > 15
          ? this.$store.state.login.firstName.substring(0, 15)
          : this.$store.state.login.firstName;
      },
    },
    accountId: {
      get() {
        return this.$store.state.account.accountId;
      },
    },
    disabledLogOut: {
      get() {
        return this.$store.state.login.disabledLogOut;
      },
    },
    displayLogin: function () {
      return this.$route.meta.showLogin && !this.loggedIn;
    },
    displayApply: function () {
      return this.$route.meta.showApply && !this.loggedIn;
    },
    displayUnlessPortal: function () {
      return !this.$route.meta.portal;
    },
    hidelogin: function () {
      return this.$route.meta.hidelogin;
    },
    isLoginPage: function () {
      return this.$route.meta.isLoginPage;
    },
    isEsignPage: function () {
      return this.$route.meta.isEsignPage;
    },
    isPortalPage: function () {
      if (
        this.$route.meta.portal == true ||
        this.$route.meta.isPortalNavDisplay == true
      ) {
        return true;
      }

      return false;
    },
    isAppPage: function () {
      return this.$route.meta.isAppPage;
    },
    isHome: function () {
      return this.$route.meta.isHome;
    },
    isEsignContact: function () {
      return this.$route.meta.isEsignContact;
    },
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    login() {
      //if (!this.errors.first("email")) {
      this.$router.push({ name: "login" });
      //}
    },
    async logout() {
      if (this.loggedIn) {
        if (this.$store.state.login.token == undefined) {
          const tokenResult = await this.$store.dispatch("createToken");
          this.$store.state.login.token = tokenResult.token.writtenToken;
        }
        this.$store.dispatch("logout");
      } else {
        this.$store.dispatch("logoutIfNotSignedIn");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button-orange {
  @media (max-width: 768px) and (min-width: 540px) {
    position: relative;
    top: -20px;
  }
  @media (max-width: 539px) and (min-width: 481px) {
    position: relative;
    top: -50px;
  }
  @media (max-width: 360px) {
    position: relative;
    top: 30px;
  }
  @media (max-width: 400px) {
    width: 87% !important;
  }
}

.button-orange {
  @media (max-width: 360px) {
    margin-top: 15px;
  }
  @media (min-width: 480px) and (max-width: 539px) {
    top: -40px;
  }
}
.nav-active-link {
  color: #1e4488 !important;
  font-weight: bold;
}

.border-md-right {
  border-right: 2px solid $light-gray;
  height: 22px;
  padding-left: 0px;
}
.button-orange-header {
  padding: 14px;
  background-color: $orange;
  color: $absolute-white;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  margin: 10px;

  @media (max-width: 386px) {
    font-size: 14px;
  }
}

.callusesignmobile {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  background-color: #e75300; //#EE6323;
  color: white;
  font-size: 16px;
  margin-right: 14px;
  text-align: center;
  font-weight: bold;

  @media (max-width: 420px) {
    margin-right: 0px;
    font-size: 14px;
  }

  @media (max-width: 376px) {
    margin-right: 0px;
    font-size: 11px;
    padding-left: 2px;
    padding-right: 2px;
  }
  @media (max-width: 360px) {
    margin-right: 0px;
    font-size: 9px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

header {
  display: flex;
  justify-content: center;
  background: $absolute-white;
  z-index: 9990;

  .wrapper-full {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 1300px;
    padding-right: 10px;
    margin-bottom: -0.25%;
    @media (min-width: 1020px) {
      margin-bottom: 0.5%;
    }
    .top-nav {
      right: 15%;
      margin-bottom: -15px;
    }

    .links {
      display: flex;
      justify-content: space-around;
      font-weight: bold;
      color: #53575a;
      float: right;

      a {
        margin: 0px 18px;
      }
      a:hover {
        color: #1e4488 !important;
      }
      a:focus {
        color: $blue;
      }
      #welcomeback:focus {
        //color: $blue;
        outline: 2px solid #1e4388;
      }

      .hello {
        font-style: italic;
        width: auto;
        margin-right: 10px;
      }
    }
    .wrapper-call-login {
      float: right;
      margin: -2px 18px 12px 18px;
    }

    .login-link {
      color: $orange !important;
    }
    .login-link:hover {
      color: $orange !important;
    }

    .Newlogin-link {
      padding: 10px 0px;
      background-color: $blue;
      color: $absolute-white;
      border-radius: 5px;
      text-transform: uppercase;
      min-width: 106px;
      display: inline-block;
      text-align: center;
      margin-left: 32px;
    }
    .Newlogin-link:hover {
      color: $absolute-white !important;
    }
    .call {
      display: inline-block;
      padding: 0px;
      margin: 10px 0px;
      color: #53575a;
    }

    .login-wrapper {
      width: 300px;
      margin-top: 20px;

      .login {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        input {
          width: 240px;
          margin-right: 4px;
        }

        .login-button {
          margin: 0 4px;
          height: 30px;
          cursor: pointer;
        }
      }

      .error {
        position: absolute;
        transform: translate(6px, -14px);
        color: $error;
        font-size: 10px;
      }

      .login-links {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 10px;
        margin: 4px 46px 0 4px;
      }
    }
    .apply {
      width: 136px;
    }

    .logout {
      width: 250px;
      color: $gray;
    }

    .logout-button {
      background-color: $orange;
      padding: 4px 16px;
      font: 25pt;
    }

    .logo a {
      width: 0px;
      display: inline-block;
    }
  }

  .user {
    display: none;
  }

  .tab {
    position: absolute;
    top: 84px;
    transform: translateX(356px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 276px;
    padding: 8px 12px;
    background: $orange;
    color: $absolute-white;
    box-shadow: 0 3px 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 9990;

    .call-icon {
      height: 30px;
    }
  }

  .xact-logo {
    height: 45px;
    padding: 10px;
    margin: 20px 0px 0px 5px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #e75300; //#Ee6323;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ee6323 transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  @media (max-width: 1023px) {
    position: fixed;
    width: 100%;
    flex-flow: column nowrap;

    .wrapper-full {
      display: none;
    }

    .wrapper-mob {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .logo {
        width: 100%;
        margin-right: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
        z-index: 9980;

        .fas {
          margin-right: 20px;
        }
      }
    }

    .login-mob {
      display: flex;
      flex-flow: column nowrap;
      background: $white;
      padding: 10px 0;
      box-shadow: 0 3px 2px 1px rgba(0, 0, 0, 0.1);
      z-index: 9970;

      .login {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      }

      input {
        margin: 0 4px;
        width: 240px;
      }
      .login-button {
        margin-left: 8px;
      }

      .login-links {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 10px;
        width: 240px;
        padding-right: 40px;
        margin: 4px auto 0;
      }
    }

    .xact-logo {
      height: 40px;
      margin: 10px 0 0 10px;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }

    .menu {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      box-shadow: 0 3px 2px 1px rgba(0, 0, 0, 0.1);
      z-index: 9960;

      .apply {
        margin: 30px;
        align-self: stretch;

        .button-purple {
          width: 100%;
        }
      }

      .item {
        width: 100%;
        padding: 30px;
        font-size: 16px;
        border-top: 1px solid $border;
      }
    }

    .user {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      font-weight: 700;
      padding: 6px 30px;

      img {
        height: 40px;
        margin-right: 15px;
      }

      .hello {
        text-transform: uppercase;
        font-size: 16px;
        font-style: italic;
      }

      .account-number {
        font-size: 10px;
        margin-top: 4px;
      }
    }

    .tab {
      position: static;
      transform: none;
      width: 100%;
      padding: 8px 0;
      justify-content: center;
      box-shadow: none;

      .call-icon {
        margin-right: 8px;
      }
    }

    .dropped {
      background: #ee6323;
      -webkit-transition: background 0.15s ease-in-out;
      -moz-transition: background 0.15s ease-in-out;
      -o-transition: background 0.15s ease-in-out;
      transition: background 0.15s ease-in-out;

      img,
      i {
        filter: brightness(0%) invert(100%);
      }
    }
  }
}

// side nav bar
.sidenav {
  height: 100%;
  width: 70%;
  position: fixed;
  z-index: 9980;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 30px;
  border: 1px solid #ededed;
}

.sidenav a {
  padding: 15px 8px 15px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #1e4488;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #e0dede;
}

.sidenav a:hover {
  color: #ee6323;
  border-bottom: 1px solid #868484;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 35px;
  margin-left: 50px;
  border-bottom: none !important;
  color: #868484;
}

.sidenav .login-link {
  color: $orange;
}

.mb-user {
  width: 100%;
  position: absolute;
  margin-top: 30%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  font-style: italic;
  letter-spacing: 1.5px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 14px;
  }
}

@media (min-width: 480px) {
  .sidenav {
    width: 50%;
  }
}
@media (min-width: 700px) {
  .sidenav {
    width: 40%;
  }
}
@media (min-width: 800px) {
  .sidenav {
    width: 30%;
  }
}
@media (max-width: 991px) {
  a.de-border {
    color: white;
    text-transform: uppercase;
    text-decoration: underline !important;
  }
}
</style>