<template>
  <main class="maincontent">
    <PortalInterrupter v-if="accountclosed">
      <template v-slot:para1>
        Your <XactFlexBrandedText /> account became
        inactive and was closed.
      </template>
      <template v-slot:para2>
        If you’d like to reopen your account, just complete the application.
      </template>
      <template v-slot:button1>
        <button
          class="button button-float"
          alt="applynow"
        >
          Apply Now
        </button>
      </template>
    </PortalInterrupter>
  </main>
</template>

<script>
import { PortalInterrupter, XactFlexBrandedText } from "@/components";
export default {
  name: "AccountInterrupterClosed",
  title: "Your Account",
  components: {
    PortalInterrupter,
    XactFlexBrandedText,
  },
  data() {
    return {
      accountclosed: true,
    };
  },

  methods: {
    apply() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  margin: 0;

  @media (max-width: 992px) {
    margin: 73px 0 0 0 !important;
  }
}
</style>